<template>
    <div class="Detail">
        <div class="pic">
            <VanImage
                    width="100%"
                    
                    fit="cover"
                    lazy-load
                    :src="info.images"  @click.stop="bindImagePreview(info.images)"
            />
        </div>
        <div class="tit">{{info.title}}</div>
        <div class="newsfooter">
            <span>{{info.time}}</span>
            <span class="cityname">{{info.city}}</span>
            <span class="storename font-color">{{info.store_name}}</span>
        </div>
        <div class="desc" v-html="info.detailspage"></div>
        <Backtop></Backtop>
    </div>
</template>

<script>
    import Backtop from "./../components/Backtop";
    import { DynamicDetails } from "@api/tasting";
    import { Icon, List, Toast, Tab, Tabs, Swipe, SwipeItem, Rate, ImagePreview, Loading } from "vant";
    import { Image as VanImage } from 'vant';
    import BrandList from "./../components/brandList";
    export default {
        name: "Detail",
        data () {
            return {
                id: '',
                info: '',
            }
        },
        components: {
            Icon, List, VanImage, Backtop, Tab, Tabs, Swipe, SwipeItem, Rate, BrandList, ImagePreview, Loading, ImagePreview
        },
        created() {
            this.id=this.$route.query.id;
            this.$store.state.app.storeid=this.$route.query.store_id;
        },
        mounted: function() {
            this.getDynamicDetails();
        },
        methods: {
            //预览图片
            bindImagePreview: function(url) {
                ImagePreview([url]);
            },
            getDynamicDetails: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                DynamicDetails({
                    id: this.id,
                }).then(res => {
                    Toast.clear();
                    this.info=res.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">
.tit{font-size: 0.36rem;font-weight: bold;line-height: 0.5rem;padding: 0.3rem;overflow: hidden;}
.desc{font-size: 0.2rem;text-align: justify;padding: 0.3rem;overflow: hidden;}
.desc Image{max-width:100%;}
.newsfooter{
    font-size: 0.2rem;padding: 0 0.3rem;overflow: hidden;
    span{float: left;display: inline-block;line-height: 0.3rem;}
    .cityname{margin: 0 0.1rem;max-width: 1.4rem;}
    .storename{float: right;max-width: 2rem;}
}
// .bit-box{
//     position:fixed;
//     bottom: 0.1rem;
// }
// .bit{
//     text-align: center;padding: 0.1rem 0.3rem;border-radius:0.4rem;font-size: 0.2rem;color: #fff;
//     margin: 0 auto;
// }
</style>