<template>
    <div class="brandList">
        <Tabs v-model="activeName" @click="changetab" :swipe-threshold="1">
            <tab :name="item.id" v-for="(item, index) in brandList" :key="index" v-if="item.wcj_image_logo">
                <template #title>
                    <div class="brandpic" :class="activeName==item.id?'brandpiccur':''">
                        <img :src="item.wcj_image_logo" />
                    </div>
                </template>
            </tab>
        </Tabs>
    </div>
</template>
<script>
import { getBrand } from "@api/tasting";
import { Toast, Tab, Tabs, Rate } from "vant";
import { Image as VanImage } from 'vant';
export default {
    name: "brandList",
    props: {
        activeName: {
            type: Number,
            value: 0
        },
        type: {
            type: Number,
            value: 0
        },
        index: {
            type: String,
            value: ''
        }
    },
    data: function() {
        return {
            brandList: [],
            type: 0,
        };
    },
    components: {
        VanImage, Tab, Tabs, Rate
    },
    created: function() {
        
    },
    mounted: function() {
        this.getBranddata();
    },
    methods: {
        getBranddata: function() {
            getBrand({
                page: 1,
            }).then(res => {
                this.brandList=res.data;
                this.$emit("brandListdata", this.brandList);
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        changetab: function() {
            if(this.type==1){
                this.$emit("clickBrandBrand", this.activeName);
            }else{
                var id='';
                this.brandList.forEach((item, index)=>{
                    if(item.id===this.activeName){
                        if(item.product.length){
                            id=item.product[0].id;
                        }
                    }
                })
                if(id){
                    this.$emit("clickBrand", id);
                    this.$emit("clickBrandBrand", this.activeName);
                }else{
                    Toast.fail({
                        message: '该品牌下暂无产品',
                        forbidClick: true,
                    });
                }
            }
        },
    }
};
</script>
<style scoped lang="scss">
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        // .van-tabs__line::before{
        //     background:#f4f4f4;
        //     border-left: 1px solid #b6b6b6;
        //     border-top: 1px solid #b6b6b6;
        //     width: 0.2rem;
        //     height: 0.2rem;
        //     display: block;
        //     content: '';
        //     margin: 0 auto;transform: rotate(45deg);
        // }
    }
}
.brandList{
    border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #e8e8e8;border-radius: 0.1rem;overflow: hidden;height: 0.7rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
    .brandpiccur{border: 1px solid #4c4744;}
}
</style>