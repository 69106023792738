<template>
    <a href="javascript:void(0);" class="backtop" @click="backtop" v-if="isTop">Top</a>
</template>

<script>
    export default {
        name: "Backtop",
        data: function() {
            return {
                isTop: false,
            };
        },
        mounted: function() {
            window.addEventListener("scroll",this.showbtn,true);
        },
        methods: {
            // 显示回到顶部按钮
            showbtn: function(){
                let that = this;
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                let browserHeight = window.outerHeight;
                if (scrollTop > browserHeight) {
                    that.isTop = true;
                } else {
                    that.isTop = false;
                }
            },
            backtop: function(){
                var timer = setInterval(function(){
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5);
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    this.isTop = true;
                    if(osTop === 0){
                        clearInterval(timer);
                    }
                },30)
            }
        }
    }
</script>

<style scoped lang="scss">
.backtop{
    position: fixed;
    right: 0.3rem;
    bottom: 2rem;
    z-index: 100;
    width: 0.8rem;
    height: 0.8rem;
    background: #ccc;
    text-align: center;
    line-height: 0.8rem;
    text-decoration: none;
    border-radius: 0.8rem;
    display: block;
}
</style>